//动态路由
import Layout from '../pages/layout';
import dealerManage from './dealerManage';
import projectManagement from './projectManagement';
import actManage from './actManage';
import mediaCenter from './mediaCenter';
import personalCenter from './personalCenter';
import auditManage from './auditManage';
import application from './application';
import creativeIdea from './creativeIdea';
import dealerHotList from './dealerHotList';
import fhlUser from './fhlUser';
import XHSAgencyRouter from './XHSAgencyRouter';

console.log('小红书路由 async router', XHSAgencyRouter);
// ---------- 系统管理 -------------

export default [
  // {
  //   path: "",
  //   component: Layout,
  //   meta: {title: "播主搜索", icon: "team"},
  //   name: "author",
  //   children: [
  //     {
  //       path: "author/manage",
  //       name: "author:manage",
  //       component: () => import("@/pages/app/author/Manage.vue"),
  //       meta: {title: "找播主号"}
  //     },
  //     {
  //       path: "author/authorization/manage",
  //       name: "authorization:manage",
  //       component: () => import("@/pages/app/authorizationManage/Manage.vue"),
  //       meta: {title: "授权管理"}
  //     },
  //     {
  //       path: "author/:authorId",
  //       name: "author:analysis",
  //       hidden: true,
  //       component: () => import("@/pages/app/authorAnalysis/Manage.vue"),
  //       meta: {title: "播主分析", noPadding: true},
  //       props: true
  //     },
  //     {
  //       path: "live/:roomId",
  //       name: "live:analysis",
  //       hidden: true,
  //       component: () => import("@/pages/app/liveAnalysis/Manage.vue"),
  //       meta: {title: "直播分析", noPadding: true},
  //       props: true
  //     },
  //     {
  //       path: "author/authorization/:authorId",
  //       name: "author:authorization",
  //       hidden: true,
  //       component: () => import("@/pages/app/authorAuthorization/AuthorizationTabs.vue"),
  //       meta: {title: "播主授权"},
  //       props: true
  //     }
  //     /*,{
  //       path: 'authorization/:authorId/service',
  //       name: 'authorization:servicePlatform',
  //       hidden: true,
  //       component: () => import('@/pages/app/authorAuthorization/'),
  //       meta: { title: '服务平台授权' },
  //       props: true
  //     }*/
  //   ]
  // },

  // {
  //   path: "",
  //   name: "aweme",
  //   component: Layout,
  //   meta: {title: "视频数据", icon: "form"},
  //   children: [
  //     {
  //       path: "aweme/manage",
  //       name: "aweme:manage",
  //       component: () => import("@/pages/app/aweme/Manage.vue"),
  //       meta: {title: "找视频"}
  //     },
  //     {
  //       path: "aweme/:awemeId",
  //       name: "aweme:analysis",
  //       hidden: true,
  //       component: () => import("@/pages/app/awemeAnalysis/NewVideoDetail.vue"),
  //       meta: {title: "视频分析", noPadding: true},
  //       props: true
  //     }
  //   ]
  // },
// {
//   path: '',
//   name:'live',
//   component: Layout,
//   meta: { title: '直播监控', icon: 'table' },
//   children: [{
//     path: 'liveSquare/manage',
//     name: 'liveSquare:manage',
//     component: () => import('@/pages/app/liveSquare/Manage.vue'),
//     meta: { title: '直播广场' }
//   },{
//     path: 'liveSquare/manage/:groupId',
//     name: 'liveSquare:group',
//     hidden: true,
//     component: () => import('@/pages/app/liveSquare/Manage.vue'),
//     meta: { title: '直播广场' },
//     props: true
//   }]
// },
  {
    path: '',
    name: 'favorites',
    component: Layout,
    meta: { title: '我的收藏', icon: 'profile' },
    children: [{
      path: 'favorites/author',
      name: 'favorites:author',
      component: () => import('@/pages/app/favoriteAuthor/Manage.vue'),
      meta: { title: '收藏的播主' },
    }, {
      path: 'favorites/group/:groupId',
      name: 'favorites:authorAnalysis',
      hidden: true,
      component: () => import('@/pages/app/groupAnalysis/Manage.vue'),
      meta: { title: '播主组内分析', noPadding: true },
      props: true,
    },
      {
        path: 'favorites/aweme',
        name: 'favorites:aweme',
        component: () => import('@/pages/app/favoriteAweme/Manage.vue'),
        meta: { title: '收藏的视频' },
      },
    ],
  },

  // {
  //   path: "",
  //   name: "polymers",
  //   component: Layout,
  //   meta: {title: "聚合榜单", icon: "crown"},
  //   children: [
  //     {
  //       path: "polymers/video_list",
  //       name: "polymers:video_list",
  //       component: () => import("@/pages/app/polymers/video_list.vue"),
  //       meta: {title: "热门视频榜"}
  //     },
  //     {
  //       path: "polymers/car_list",
  //       name: "polymers:car_list",
  //       component: () => import("@/pages/app/polymers/car_list.vue"),
  //       meta: {title: "汽车榜"}
  //     },
  //     {
  //       path: "polymers/music_list",
  //       name: "polymers:music_list",
  //       component: () => import("@/pages/app/polymers/music_list.vue"),
  //       meta: {title: "音乐榜"}
  //     },
  //     {
  //       path: "polymers/dealer_list",
  //       name: "polymers:dealer_list",
  //       component: () => import("@/pages/app/polymers/dealer_list.vue"),
  //       meta: {title: "经销商热门榜"}
  //     },
  //     {
  //       path: "polymers/topic_list",
  //       name: "polymers:topic_list",
  //       component: () => import("@/pages/app/polymers/topic_list.vue"),
  //       meta: {title: "热点榜"}
  //     },
  //     {
  //       path: "polymers/topic_detail",
  //       name: "polymers:topic_detail",
  //       component: () => import("@/pages/app/polymers/topic_detail.vue"),
  //       meta: {title: "热点详情"},
  //       hidden: true
  //     },
  //     {
  //       path: "polymers/challenge_list",
  //       name: "polymers:challenge_list",
  //       component: () => import("@/pages/app/polymers/challenge_list.vue"),
  //       meta: {title: "挑战榜"}
  //     },
  //     {
  //       path: "polymers/challenge_detail",
  //       name: "polymers:challenge_detail",
  //       component: () => import("@/pages/app/polymers/challenge_detail.vue"),
  //       meta: {title: "挑战详情", noPadding: true}
  //     },
  //     {
  //       path: "polymers/dealer_detail",
  //       name: "polymers:dealer_detail",
  //       component: () => import("@/pages/app/polymers/dealer_deatil.vue"),
  //       meta: {title: "短视频榜详情", noPadding: true}
  //     },
  //     {
  //       path: "polymers/keywords_monitoring",
  //       name: "keywords_monitoring",
  //       component: () => import("@/pages/app/polymers/keywords_monitoring.vue"),
  //       meta: {title: "关键词监控列表"}
  //     },
  //     {
  //       path: "polymers/keywords_monitoring_detail",
  //       name: "polymers:keywords_monitoring_detail",
  //       component: () => import("@/pages/app/polymers/keywords_monitoring_detail.vue"),
  //       meta: {title: "关键词监控详情", noPadding: true, hidden: true}
  //     }
  //   ]
  // },

  // {
  //   path: "",
  //   name: "discourse",
  //   component: Layout,
  //   meta: {title: "话术管理", icon: "notification"},
  //   children: [{
  //     path: "discourse/record",
  //     name: "discourse:record",
  //     component: () => import("@/pages/app/discourse/discourseRecord.vue"),
  //     meta: {title: "话术录入"}
  //   }]
  // },

  // {
  //   path: "",
  //   name: "console",
  //   component: Layout,
  //   meta: {title: "剪辑管理", icon: "laptop"},
  //   children: [
  //     {
  //       path: "/cloudClip/liveTableList",
  //       name: "cloudClip:liveTableList",
  //       component: () => import("@/pages/app/cloudClip/liveTableList"),
  //       meta: {title: "直播场次列表"}
  //     },
  //     {
  //       path: "/cloudClip/cliptTableList",
  //       name: "cloudClip:cliptTableList",
  //       component: () => import("@/pages/app/cloudClip/cliptTableList"),
  //       meta: {title: "直播素材库"}
  //     },
  //     {
  //       path: "/cloudClip/materialDetails",
  //       name: "cloudClip:materialDetails",
  //       component: () => import("@/pages/app/cloudClip/materialDetails"),
  //       meta: {title: "素材标签管理"},
  //       hidden: true
  //     }

  /*  {
        path: '/console/edit',
        name: 'console:edit',
        hidden: true,
        component: () => import('@/pages/app/console/edit.vue'),
        meta: {title: '剪辑控制台', noPadding: true}
    },
    {
        path: '/console/live',
        name: 'console:live',
        component: () => import('@/pages/app/console/live.vue'),
        meta: {title: '直播列表'}
    },
    {
        path: '/console/list',
        name: 'console:list',
        component: () => import('@/pages/app/console/list.vue'),
        meta: {title: '剪辑列表'}
    } */
  //   ]

  // },

  {
    path: '',
    name: 'operator',
    admin: true,
    component: Layout,
    meta: {
      title: '其它', icon: 'codepen', group: 'base',
    },
    children: [{
      path: 'operator/manage',
      name: 'operator:manage',
      component: () => import('@/pages/app/operator/Manage.vue'),
      meta: { title: '操作员管理' },
    }],
  },

  {
    path: '/liveMonitor/room/:roomId',
    name: 'liveMonitor:room',
    hidden: true,
    component: () => import('@/pages/app/liveMonitor'),
    meta: { title: '实时直播监控', noPadding: true },
    props: true,
  },

  // {
  //   path: "/douyin/shouquan",
  //   name: "douyin:shouquan",
  //   hidden: true,
  //   component: () => import("@/pages/app/other/douyin.vue"),
  //   meta: {title: "抖音授权", noPadding: true}
  // },

  // {
  //   path: "/kuaishou/accredit",
  //   name: "kuaishou:accredit",
  //   hidden: true,
  //   component: () => import("@/pages/app/other/kuaishou.vue"),
  //   meta: {title: "快手授权", noPadding: true}
  // },

  {
    path: '/result/success',
    name: 'result:success',
    hidden: true,
    component: () => import('@/pages/app/other/success.vue'),
    meta: { title: '结果页', noPadding: true },
  },

  // {
  //   path: "/framer/login",
  //   name: "framer:login",
  //   hidden: true,
  //   component: () => import("@/pages/app/other/framer.vue"),
  //   meta: {title: "创作者授权", noPadding: true}
  // },

  // {
  //   path: "/bindDCDAccount",
  //   name: "bindDCDAccount",
  //   meta: {title: "绑定懂车帝", icon: "lock"},
  //   component: () => import("@/pages/app/bindDCDAccount/index.vue")
  // },

  // {
  //   path: "/bindDCDAccount/success",
  //   name: "bindDCDAccount:success",
  //   hidden: true,
  //   meta: {title: "绑定懂车帝成功页", icon: "lock"},
  //   component: () => import("@/pages/app/bindDCDAccount/success.vue")
  // },

  {
    path: '/console/edit',
    name: 'console:edit',
    hidden: true,
    component: () => import('@/pages/app/console/edit.vue'),
    meta: { title: '剪辑控制台', noPadding: true },
  },

  // 活动
  // {
  //   path: "",
  //   name: "training",
  //   component: Layout,
  //   meta: {title: "活动", icon: "project"},
  //   children: [{
  //       path: "training/index",
  //       name: "training:index",
  //       component: () => import("@/pages/app/trainingList/index.vue"),
  //       meta: {title: "活动列表"}
  //     }, {
  //       path: "training/account",
  //       name: "training:account",
  //       hidden: true,
  //       component: () => import("@/pages/app/trainingList/account.vue"),
  //       meta: {title: "媒体帐号列表"}
  //     },
  //     {
  //       path: "training/configurationTemplate",
  //       name: "configurationTemplate",
  //       hidden: true,
  //       component: () => import("@/pages/app/trainingList/configurationTemplate.vue"),
  //       meta: {title: "配置导出模板"}
  //     },
  //     {
  //       path: "training/kpi_detail",
  //       name: "kpi_detail",
  //       hidden: true,
  //       component: () => import("@/pages/app/trainingList/kpi_detail.vue"),
  //       meta: {title: "KPI详情"}
  //     },
  //     {
  //       path: "training/dealer-detail",
  //       name: "dealer-detail",
  //       hidden: true,
  //       component: () => import("@/pages/app/trainingList/dealer-detail.vue"),
  //       meta: {title: "经销商详情"}
  //     },
  //     {
  //       path: "training/template-detail",
  //       name: "template-detail",
  //       hidden: true,
  //       component: () => import("@/pages/app/trainingList/template-detail.vue"),
  //       meta: {title: "模板使用详情"}
  //     },
  //     //聚金塔活动列表
  //     {
  //       path: "jjtActives/index",
  //       name: "jjtActives:index",
  //       component: () => import("@/pages/app/jjtActives/index.vue"),
  //       meta: {title: "聚金塔活动列表"}
  //     },

  //     //聚金塔活动详情
  //     {
  //       path: "jjtActives/detail/:groupId",
  //       name: "jjtActives:detail",
  //       component: () => import("@/pages/app/jjtActives/detail.vue"),
  //       meta: {title: "聚金塔活动详情"},
  //       hidden: true,
  //     }
  //   ]
  // },

  {
    path: '/training/diagnostic',
    name: 'training:diagnostic',
    hidden: true,
    component: () => import('@/pages/app/trainingList/diagnostic.vue'),
    meta: { title: '诊断报告' },
  },

  // {
  //   path: "/regulation",
  //   name: "regulation",
  //   component: Layout,
  //   meta: {title: "投放助手", icon: "control"},
  //   children: [
  //     {
  //       path: "/regulation/author",
  //       name: "regulation:author",
  //       component: () => import("@/pages/app/regulation/author.vue"),
  //       meta: {title: "监控主播列表"}
  //     },
  //     {
  //       path: "/regulation/video",
  //       name: "regulation:video",
  //       component: () => import("@/pages/app/regulation/video.vue"),
  //       meta: {title: "监控视频列表"}
  //     },
  //     {
  //       path: "/regulation/add",
  //       name: "regulation:add",
  //       component: () => import("@/pages/app/regulation/addRegulation.vue"),
  //       meta: {title: "添加监控主播页", noPadding: true},
  //       hidden: true
  //     }
  //   ]
  // },

  // {
  //   path: "/wechatMessage",
  //   name: "wechatMessage",
  //   component: Layout,
  //   meta: {title: "微控群信息管理", icon: "wechat"},
  //   children: [
  //     {
  //       path: "/wechatMessage/list",
  //       name: "wechatMessage:list",
  //       component: () => import("@/pages/app/wechatMessage/tagList.vue"),
  //       meta: {title: "标签列表页"}
  //     },
  //     {
  //       path: "/wechatMessage/group",
  //       name: "wechatMessage:group",
  //       component: () => import("@/pages/app/wechatMessage/groupList.vue"),
  //       meta: {title: "群广播列表"}
  //     },
  //     {
  //       path: "/wechatMessage/create",
  //       name: "wechatMessage:create",
  //       component: () => import("@/pages/app/wechatMessage/create.vue"),
  //       meta: {title: "创建群广播列表", noPadding: true},
  //     },
  //     {
  //       path: "/wechatMessage/send",
  //       name: "wechatMessage:send",
  //       component: () => import("@/pages/app/wechatMessage/sendMessage.vue"),
  //       meta: {title: "发送广播", noPadding: true},
  //       hidden: true
  //     },
  //   ]
  // },

  // {
  //   path: "/dataAnalyse",
  //   name: "dataAnalyse",
  //   component: Layout,
  //   meta: {title: "数据分析", icon: "file-sync"},
  //   children: [
  //     {
  //       path: "/dataAnalyse/rule",
  //       name: "dataAnalyse:rule",
  //       component: () => import("@/pages/app/dataAnalyse/ruleTable.vue"),
  //       meta: {title: "自助取数规则"}
  //     },
  //     {
  //       path: "/dataAnalyse/exportResult",
  //       name: "dataAnalyse:group",
  //       component: () => import("@/pages/app/dataAnalyse/exportResult.vue"),
  //       meta: {title: "导出结果"}
  //     },
  //     {
  //       path: "/dataAnalyse/create",
  //       name: "dataAnalyse:create",
  //       component: () => import("@/pages/app/dataAnalyse/create.vue"),
  //       meta: {title: "新建取数规则", noPadding: true},
  //     },
  //   ]
  // },

  // {
  //   path: "/creativeCollection",
  //   name: "creativeCollection",
  //   component: Layout,
  //   meta: {title: "创意采集", icon: "picture"},
  //   children: [
  //     {
  //       path: "materialCollection",
  //       name: "creativeCollection:materialCollection",
  //       component: () => import("@/pages/app/creativeCollection/materialCollection"),
  //       meta: {title: "素材采集"}
  //     },
  //     {
  //       path: "followAccount",
  //       name: "creativeCollection:followAccount",
  //       component: () => import("@/pages/app/creativeCollection/followAccount"),
  //       meta: {title: "关注帐号"}
  //     }
  //   ]
  // },

  // {
  //   path: "/templateAnalysis",
  //   name: "templateAnalysis",
  //   component: Layout,
  //   meta: {title: "模板分析", icon: "line-chart"},
  //   children: [
  //     {
  //       path: "usageData",
  //       name: "templateAnalysis:usageData",
  //       component: () => import("@/pages/app/templateAnalysis/usageData"),
  //       meta: {title: "使用数据"}
  //     },
  //     {
  //       path: "usageDetail",
  //       name: "templateAnalysis:usageDetail",
  //       component: () => import("@/pages/app/templateAnalysis/usageDetail"),
  //       meta: {title: "使用详情"},
  //       hidden: true
  //     }
  //   ]
  // },
  // {
  //   path: "/contentProduction",
  //   name: "contentProduction",
  //   component: Layout,
  //   meta: {title: "内容生产", icon: "book"},
  //   children: [
  //     {
  //       path: "/WorkOrderManagementList",
  //       name: "WorkOrderManagementList",
  //       component: () => import("@/pages/app/contentProduction/WorkOrderManagementList"),
  //       meta: {title: "工单管理"}
  //     },
  //     {
  //       path: "/WorkOrderManagementPage",
  //       name: "WorkOrderManagementPage",
  //       component: () => import("@/pages/app/contentProduction/WorkOrderManagementPage"),
  //       meta: {title: "工单管理"},
  //       hidden: true
  //     },
  //     {
  //       path: "/WorkOrderManagementDetail",
  //       name: "WorkOrderManagementDetail",
  //       component: () => import("@/pages/app/contentProduction/WorkOrderManagementDetail"),
  //       meta: {title: "工单管理详情"},
  //       hidden: true
  //     },
  //     {
  //       path: "/TaskManagementList",
  //       name: "TaskManagementList",
  //       component: () => import("@/pages/app/contentProduction/TaskManagementList"),
  //       meta: {title: "任务管理"}
  //     },
  //     {
  //       path: "/TaskManagementDetail",
  //       name: "TaskManagementDetail",
  //       component: () => import("@/pages/app/contentProduction/TaskManagementDetail"),
  //       meta: {title: "任务管理详情"},
  //       hidden: true
  //     },
  //     {
  //       path: "/progressOfTheStatisticsList",
  //       name: "progressOfTheStatisticsList",
  //       component: () => import("@/pages/app/contentProduction/progressOfTheStatisticsList"),
  //       meta: {title: "进度统计"}
  //     },
  //     {
  //       path: "/templateAnalysisList",
  //       name: "templateAnalysisList",
  //       component: () => import("@/pages/app/contentProduction/templateAnalysisList"),
  //       meta: {title: "模板分析"}
  //     },
  //   ]
  // },
  // 取数工单
  {
    path: '/workOrder',
    name: 'workOrder',
    component: Layout,
    meta: { title: '取数工单', icon: 'snippets' },
    children: [
      {
        path: '/workOrder/record',
        name: 'WorkOrderRecord',
        component: () => import('@/pages/app/workOrder/record'),
        meta: { title: '工单记录' },
      },
      {
        path: '/workOrder/created',
        name: 'WorkOrderCreated',
        component: () => import('@/pages/app/workOrder/created'),
        meta: { title: '创建工单' },
      },
      {
        path: '/workOrder/detail',
        name: 'WorkOrderDetail',
        component: () => import('@/pages/app/workOrder/detail'),
        meta: { title: '工单详情' },
        hidden: true,
      },
      {
        path: '/workOrder/management',
        name: 'WorkOrderManage',
        component: () => import('@/pages/app/workOrder/management'),
        meta: { title: '工单流程管理' },
      },
      {
        path: '/workOrder/dataTemplate',
        name: 'dataTemplate',
        component: () => import('@/pages/app/workOrder/dataTemplate'),
        meta: { title: '数据模板配置' },
      },
    ],
  },
  {
    path: '/workOrder/detailPhone',
    name: 'WorkDetailPhone',
    component: () => import('@/pages/app/workOrder/detail'),
    meta: { title: '工单详情', noPadding: true },
    hidden: true,
  },
  {
    path: '/chatgpt',
    name: 'chatgpt',
    component: () => import('@/pages/app/chatgpt/detail'),
    meta: { title: 'chatgpt', noPadding: true },
    hidden: true,
  },
  // 项目管理
  { ...projectManagement },
  // 经销商管理
  { ...dealerManage },
  // 活动管理
  { ...actManage },
  // 媒体中心
  { ...mediaCenter },
  // 个人中心
  { ...personalCenter },
  // 审核管理
  { ...auditManage },
  // 应用
  { ...application },
  // 创意
  { ...creativeIdea },
  // 风火轮用户管理
  { ...fhlUser },
  // 小红书代运营
  { ...XHSAgencyRouter },
  // 独立页面
  { ...dealerHotList },
];
